<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-show="pageIsError"
      transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px"
      color="error"
      type="error"
    >
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading">
      <v-col class="d-flex align-center">
        <v-icon
          class="grey--text text-h4 mr-5"
          title="Go back to kanban stage"
          @click="
            $router.push({
              path: `/dashboard/admin/kanban/${kanban_type}/stage-item/${stage_item_id}/objective/list/`,
            })
          "
        >
          mdi-arrow-left-circle
        </v-icon>

        <label class="text-h5">
          
          <span> {{stage_objective.stage_item.kanban_stage.stage_name}}/ {{stage_objective.title}}/ Task List/ </span> 
          <span v-if="kanban_type === 'Deal'" class="grey--text"> ( {{stage_objective.stage_item.deal.public_name}} ) </span> 
          <span v-if="kanban_type === 'Lender on boarding'" class="grey--text"> ( {{stage_objective.stage_item.company.name}} ) </span> 
          
        </label>
      </v-col>
    </v-row>

    <v-row v-if="!pageIsLoading">
      <v-col>
        <v-data-table :headers="headers" :items="stage_objective.stage_objective_tasks" @click:row="viewTask">
          <template #item.predecessor="{ item }">
            {{ item.predecessor ? item.predecessor.username : '' }}
          </template>
          <template #item.selected_assignees="{ item }">
            <span v-for="assignee in item.selected_assignees" :key="assignee.id">
              {{ assignee.username }}, 
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn>
          create task
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '@/plugins/API';

export default {

  props: ['kanban_type', 'stage_item_id', 'stage_objective_id'],

  data: () => ({
    pageIsLoading: true,
    pageIsError: false,

    headers: [
      { text: "Count", value: "count" },
      { text: "Title", value: "title" },
      { text: "Description", value: "description" },
      { text: "Status", value: "status" },
      { text: "Day Limit", value: "day_limit" },
    ],
    
    stage_item: {},

    stage_objective: {}
  }),

  methods: {
    viewTask(value){
      this.$router.push({ path: `/dashboard/admin/kanban/${this.kanban_type}/stage-item/${this.stage_item_id}/objective/${this.stage_objective_id}/task/read/${value.id}` });
    },
    getDays(created_date) {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(created_date);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    formatDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as mm/dd/yyyy
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    },

    async readStageObjective() {
      try {
        this.pageIsLoading= true;
        
        const read_stage_objective_res = await API().get(`api/kanban/read_stage_objective/?stage_objective_id=${this.stage_objective_id}`);

        console.log(read_stage_objective_res)
        
        this.stage_objective = read_stage_objective_res.data;

        this.stage_objective.stage_objective_tasks.forEach(task => {
          task.count = this.getDays(task.createdAt)
        });
        
        this.pageIsLoading= false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    }
  },

  mounted() {
    this.readStageObjective();
  } 
};
</script>
